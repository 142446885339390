import { Button } from "@mui/material";
import React from "react";
import { Link } from "gatsby";
import * as styles from "./HeroCallout.module.scss";
import styled from "@emotion/styled";

const ColorButton = styled(Button)(({ theme: any }) => ({
  color: "black",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
}));

const HeroCallout = () => {
  return (
    <div className={styles.box}>
      <h2 style={{ marginBottom: 2 }}>Putting A Global Price On Carbon</h2>
      <p style={{ marginTop: 5 }}>
        TBL can help your organisation reduce costs, understand how regulation
        will impact you, and drive business value with our leading carbon
        optimisation service.{" "}
      </p>
      <Link to="/services/carbon-optimisation">
        <ColorButton>Learn More</ColorButton>
      </Link>
    </div>
  );
};

export default HeroCallout;
