import * as React from "react";
import IntroBlock from "../components/IntroBlock";
import Navbar from "../components/nav";
import Layout from "../Layout";
import Hero from "../components/Hero";
import AccentBar from "../components/AccentBar";
import AccentBackground from "../components/AccentBackground";
import Footer from "../components/Footer/Footer";
import LatestBlogPosts from "../components/LatestBlogPosts";
import SEO from "../components/SEO";
import anime from "animejs";
import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CTABox from "../components/CTABox";
import HeroCallout from "../components/HeroCallout";

// markup
const IndexPage = () => {
  useEffect(() => {
    const tl = anime.timeline({});

    tl.add({
      targets: ["#p1"],
      translateX: [-100, 0],
      opacity: [0, 1],
      easing: "linear",
      duration: 500,
    });

    tl.add({
      targets: ["#p2"],
      translateX: [-100, 0],
      opacity: [0, 1],
      easing: "linear",
      duration: 500,
    });

    tl.add({
      targets: ["#p3"],
      translateX: [-100, 0],
      opacity: [0, 1],
      easing: "linear",
      duration: 500,
    });

    tl.add({
      targets: ["#bar"],
      opacity: [0, 1],
      easing: "linear",
      height: [0, "100%"],
    });
  }, []);

  return (
    <Layout>
      <SEO title="Welcome" />

      <Hero imageName="indexBackground" isIndex={true}>

        <HeroCallout/>
        {/* <AccentBar>
          <h1 id="p1">People</h1>
          <h1 id="p2">Planet</h1>
          <h1 id="p3">Profit</h1>
        </AccentBar> */}
      </Hero>
      <IntroBlock>
        <h1>
          Taking a holistic view to climate change and sustainability through 3
          principles:{" "}
          <span className="accentText">Planet, People & Profit.</span>
        </h1>
        <p>
          Providing unique insight & knowledge into the implementation and
          operation of Market Based Mechanisms (MBM), helping both public and
          private sector organisations with their response to climate change and
          sustainability action in a resource constrained world.
        </p>
      </IntroBlock>

      <AccentBackground>
        <div className="text-center">
          <h2>The TBL Group</h2>

          <span className="smallDivider" />

          <p>
            Business leaders are well aware that the issue of climate change and
            sustainability (CC&S) is becoming a key strategic imperative.
            Environmental regulation around the world is a patchwork of rules
            and enforcement mechanisms, which make it difficult for businesses
            to be certain that they meet even the minimum compliance
            standards.The costs of dealing with environmental sustainability
            issues are seen as being incremental to all of the other costs of
            doing business, and there is most often, little perceived benefit.
          </p>

          <p>
            Individual business entities need to find a way to understand how
            particular issues affect (or do not affect) their business. While it
            is entirely appropriate to focus on the negative impact of climate
            change and other environmental sustainability issues, there are also
            major opportunities created, and businesses need to find ways to
            identify and take advantage of these opportunities.
          </p>

          <p>
            The TBL Risk & Opportunity Assessment is a quick way of helping
            business navigate complex issues and align business change programs
            to reduce risk and enable major opportunities.
          </p>
        </div>
      </AccentBackground>

      <section>
        <div className="text-center">
          <h2>SUSTAINABLE ACTION</h2>
          <div className="video-container">
            <iframe
              style={{ marginTop: 50 }}
              width="676"
              height="380"
              src="https://www.youtube.com/embed/uFSfev42g-4?list=PLKUX_ySVm6POlum2NB4MsbB9Tt-X_1_mE"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>

      <section>
        <div className="text-center">
          <h2>Latest Research</h2>
          <LatestBlogPosts />
        </div>
      </section>

      <Container>
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CTABox />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default IndexPage;
